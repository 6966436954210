import styles from './ContactForm.module.scss';
import classNames from "classnames";
import buttonStyles from "../../styles/Button.module.scss";
import {useRef, useState} from "react";
import FileDropzone from "../FileDropzone/FileDropzone";
import {toast} from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import {getContactValidation} from "../../services/formValidation";
import {useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import {useTranslation} from "react-i18next";
import NavLinkLocale from "../NavLinkLocale";

const ContactForm = () => {
  const { t, i18n: {language: locale }} = useTranslation();
  const reRef = useRef();
  const dropzoneRef = useRef();
  const [files, setFiles] = useState([]);

  const {
    register,
    handleSubmit,
    reset,
    setError,
    formState: { errors, isSubmitting }
  } = useForm({
    defaultValues: {
      name: '',
      email: '',
      phone: '',
      message: '',
      terms: false,
    },
    resolver: yupResolver(getContactValidation(locale))
  });


  const onSubmit = async (formData) => {
    const captcha = await reRef?.current?.executeAsync();
    reRef?.current?.execute();
    try {
      const dataToSend = new FormData();
      dataToSend.append('locale', locale);
      dataToSend.append('captcha', captcha);
      Object.keys(formData).forEach((key) => {
        dataToSend.append(key, formData[key]);
      });
      if (files.length) {
        files.forEach((file, i) => {
          dataToSend.append(`attachment${i+1}`, file);
        })
      }

      const response = await fetch('/api/contact', {
        method: 'POST',
        body: dataToSend,
      });
      if (response.ok) {
        toast.success(t('request.success'));
        reset();
        setFiles([]);
        if (dropzoneRef?.current?.reset) {
          dropzoneRef.current.reset();
        }
      } else {
        const errorsResponse = await response.json();
        Object.keys(errorsResponse).map((key) => setError(key, { type: 'server', message: errorsResponse[key] }));
      }
    } catch (err) {
      const newErrors = {};
      if (err.inner) {
        err.inner.forEach(e => {
          newErrors[e.path] = e.message;
        });
      }
      toast.error(t('request.failed'))
    }
  }

  const fields = [
    {name: 'name', label: t('label.name'), placeholder: t('placeholder.name')},
    {name: 'email', label: t('label.email'), placeholder: t('placeholder.email')},
    {name: 'phone', label: t('label.phone'), placeholder: t('placeholder.phone')},
    {name: 'message', label: t('label.message'), placeholder: t('placeholder.message')},
  ]

  return (
    <div className={styles.ContactFormSection}>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className={styles.ContactForm}
      >
        <div className={styles.FormAlert}>
            <h2 className={styles.FormAlertTitle}>{t('formAlertTitle')}</h2>
            <p>{t('formAlertMessage')}</p>
        </div>
        <ReCAPTCHA
          ref={reRef}
          size="invisible"
          sitekey={process.env.RAZZLE_PUBLIC_RECAPTCHA_SITE_KEY}
          hl={locale}
        />

        {fields.map(field => (
          <div className={classNames([
            styles.FormGroup,
            {[styles.FormGroupHasError]: !!errors[field.name]}
          ])} key={field.name}>
            <label
              className={styles.FormLabel}
              htmlFor={field.name}
            >
              {field.label}
              <span className={styles.RequiredField}>*</span>
            </label>
            {field.name === 'message' ? (
              <textarea
                id={field.name}
                {...register(field.name)}
                className={styles.FormControl}
                rows={5}
                disabled={isSubmitting}
                placeholder={field.placeholder || ''}
              />
            ) : (
              <input
                type="text"
                id={field.name}

                {...register(field.name)}
                disabled={isSubmitting}
                placeholder={field.placeholder || ''}
                className={styles.FormControl}
              />
            )}
            {
              !!errors[field.name] && (
              <div className={styles.FormErrorMessage}>{errors[field.name].message}</div>
            )}
          </div>
        ))}

        <div className={styles.FormGroup}>
          <label
            className={styles.FormLabel}
            htmlFor="dropzoneInput"
          >
            {t('label.files')}
          </label>
          <FileDropzone parentRef={dropzoneRef} onChange={setFiles} disabled={isSubmitting}/>
        </div>

        <div className={classNames([
          styles.FormGroup,
          {[styles.FormGroupHasError]: errors.terms}
        ])}>
          <label
            className={`${styles.FormLabel} ${styles.FormLabelCheckbox}`}
          >
            {t('label.terms')}
            &nbsp;
            <NavLinkLocale to="/private-policy">{t('label.termsLink')}</NavLinkLocale>
            {'.'}
            <input type="checkbox" {...register('terms')} disabled={isSubmitting}/>
            <span className={styles.FormLabelCheckboxCheckmark}></span>
            {!!errors?.terms?.message && (
              <div className={styles.FormErrorMessage}>{errors.terms.message}</div>
            )}
          </label>
        </div>

        <div className={classNames([
          styles.FormSubmit,
        ])}>
          <button
            disabled={isSubmitting}
            className={classNames([
              buttonStyles.Button,
              buttonStyles.ButtonPrimary,
              styles.FormSubmitButton,
            ])}
            type="submit"
          >{t(isSubmitting ? 'label.submitting' : 'label.submit')}</button>
        </div>
      </form>
    </div>
  );
}

export default ContactForm;
